/** JAVA ENDPOINT */
export const BASE_URL = process.env.REACT_APP_UAT_BASE_URL;
export const OAUTH_BASE_URL = process.env.REACT_APP_OAUTH_BASE_URL;
export const AUTH_TOKEN = process.env.REACT_APP_AUTH_TOKEN;
export const AUTHENTICATE_BASE_URL =
  process.env.REACT_APP_AUTHENTICATE_BASE_URL;
export const FLAGSMITH_ENV_KEY = process.env.REACT_APP_FLAGSMITH_ENV;

/** GOLANG ENDPOINT */
export const BASE_URL_GOLANG = process.env.REACT_APP_UAT_BASE_URL_GOLANG;
export const OAUTH_BASE_URL_GOLANG =
  process.env.REACT_APP_OAUTH_BASE_URL_GOLANG;
export const AUTHENTICATE_BASE_URL_GOLANG =
  process.env.REACT_APP_AUTHENTICATE_BASE_URL_GOLANG;

//path
export const CUSTOM_USERS = '/v1/banks/01/custom/users';
export const SPLASH = '/v1/banks/01/custom/contents?contentType=SPL';
export const STANDARD_TEXT = '/v1/banks/01/custom/standard-text?textType=';
export const USERS = '/v1/banks/01/users';
export const USERSV2 = '/v2/banks/01/users';
export const USERSV3 = '/v3/banks/01/users';
export const DEVICE_REG = '/custom/deviceregistration';
export const CHANGE_PHONE_NUMBER = '/updateHpNumber';
export const RESET_PASSWORD = '/v1/banks/01/custom/users/forgot-password/reset';
export const AUTHENTICATEUSER = '/oauth/authenticateuser';
export const OAUTH = '/oauth/token';
export const VALIDATE_TOKEN = '/validate/token';
export const NOTIF = '/custom/notifications';
export const LOANS = '/custom/loans';
export const PAYMENTS = '/custom/loanHist/';
export const PROFILE = '/custom/userprofile';
export const CHANGE_PIN = '/custom/password';
export const VERIFY_PAYROLL = '/custom/verifyPayroll';
export const MAINTENANCE = '/v1/banks/01/custom/maintenance';
export const CUSTOM_RAYANET = '/v1/custom/raya-partner';

//form body
export const BANK_ID = '01';
export const GRANT_TYPE = 'password';
export const CLIENT_ID = 'finacle';
export const USER_PRINCIPAL = 9000000005;
export const ACCESS_CODE = '123456';
export const CHANNEL_ID = 'I';
export const LOGIN_FLAG = '1';
export const __SRVCID__ = 'LGIN';
export const LOGIN_TYPE = '1';
export const LANGUAGE_ID = '001';
export const AUTH_MODE_TYPE = 'P';
export const AUTH_MODE = 'SPWD';
export const TOKEN_REQUIRED = 'Y';
export const RM_AUTH = '';

//For Loan Application Drop down urls
export const CODE_REFERENCES = '/v1/banks/01/codereferences';
export const PROVINCE = '/v1/banks/01/statecodereferences/ID';
export const FIELD_OF_WORKS = '/v1/banks/01/codereferences/TYOW';
export const FIELD_OF_BUSINESS = '/v1/banks/01/codereferences/BDSH';
export const MERITAL_STATUS = '/MST';
export const NUM_OF_DEPENDENTS = '/DEPN';
export const RELATIONSHIP = '/RLT';
export const GENDER = '/GEN';
export const CARD_ISSUER_BANK = '/CCBK';
export const FIELD_JOB_TITLE =
  '/custom/raya-digital-saving/master-data/jabatan';
export const FIELD_DAILY_NORMAL_TRANSACTION =
  '/custom/raya-digital-saving/master-data/normal-harian';

//For Disbursement Digital Signature Terms & Condition
export const DIGITAL_SIGN_TERMS_URL = '/custom/standard-text?textType=DTS';
export const DISBURSEMENT_USERS_URL = '/v1/banks/01/users';
export const DISBURSEMENT_DTRM_DOCUMENT = '/custom/doctext/DTRM';
export const DISBURSEMENT_DAOF_DOCUMENT = '/custom/doctext/DAOF';
export const DISBURSEMENT_DBTL_DOCUMENT = '/custom/doctext/DBTL';
export const PRIVYID_URL = '/custom/privyregistrations';
export const ESIGN_REGIST_URL = '/custom/e-sign-registration';

export const GENERATEOTP = 'generateOtp';
export const GENERATEOTP_URL = 'custom/applications';
export const VERIFYOTP = 'verifyOTP';
export const CREATElOAN = 'createloan';
export const CUSTOM_URL = 'custom/applications';
export const VALIDATEPHOTO = 'valdiatePhoto';
export const AUTO_LOGIN = 'autoLogin';
export const COMPANY_INFO = 'companydetails';
export const PASSWORD = 'password';

//Payment
export const PAYMENT_SERVICE = '/custom/servicerequests/loan-payment';
export const PAYMENT_INQUIRY = '/loanPaymentInq';
export const PAYMENT_TRANSACTION = '/loanPaymentTran';

//Element Camera New
export const API_KEY_ELEMENT =
  'iO1CMloKqf7Ex7qdWitARJpIqX85lUdGm6XvyccTJDR4n4leg7xI6swptUwQKeis';
export const CLIENT_ID_ELEMENT = 94;
export const BASE_URL_ELEMENT =
  'https://jkt-fm.discoverelement.com/web/liveness3/page/liveness_check.html';
export const ELEMENT_GET_IMAGES =
  'https://jkt-fm.discoverelement.com:9443/api/ers/spoof/images?txnId=';

//notification
export const NOTIFICATION = '/custom/notifications';

//For FAQ path
export const HELP_TOPIC = '/custom/helptopics';

// partner id
export const PARTNER_ID_BPJS = process.env.REACT_APP_PARTNER_ID_BPJS;
export const PARTNER_ID_BPJS_KASBON =
  process.env.REACT_APP_PARTNER_ID_BPJS_KASBON;
export const PARTNER_ID_PMI = process.env.REACT_APP_PARTNER_ID_PMI;
export const PARTNER_ID_GENERAL = process.env.REACT_APP_PARTNER_ID_GENERAL;
export const PARTNER_ID_WEBVIEW_APK =
  process.env.REACT_APP_PARTNER_ID_WEBVIEW_APK;
export const PARTNER_ID_GENERAL_KASBON =
  process.env.REACT_APP_PARTNER_ID_GENERAL_KASBON;
export const PARTNER_ID_RBONOSAVING =
  process.env.REACT_APP_PARTNER_ID_RBONOSAVING;

//url partner
export const KASBON_URL = process.env.REACT_APP_KASBON_URL;
export const PMI_URL = process.env.REACT_APP_PMI_URL;
export const PARTNER_RAYA_URL = process.env.REACT_APP_PARTNER_RAYA_URL;
export const PARTNER_BRIMO_URL = process.env.REACT_APP_PARTNER_BRIMO_URL;
export const PARTNER_PNM_URL = process.env.REACT_APP_PARTNER_PNM_URL;
export const PARTNER_PTPOJ_URL = process.env.REACT_APP_PARTNER_PTPOJ_URL;
export const PARTNER_BULOG_URL = process.env.REACT_APP_PARTNER_BULOG_URL;
export const PARTNER_RBONOSAVING_URL =
  process.env.REACT_APP_PARTNER_RBONOSAVING_URL;
export const PARTNER_PKSS_URL = process.env.REACT_APP_PARTNER_PKSS_URL;
